<template>
  <full-view title="Change Phone Number">
    <template v-slot:toolbar>
      <v-btn large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid">
      <v-card class="ma-5 pa-5" :style="getViewHeightStyle(76)">
        <p class="px-5 pt-3 body-2">
          Your current phone number is
          <strong>({{ getCurrentuser.phone }})</strong>, please enter your new
          number if you want to proceed:
        </p>

        <v-row>
          <v-col cols="12" sm="4">
            <v-combobox
              v-model="selectedCountry"
              :items="countryCodes"
              label="Country"
              item-text="name"
              :rule="rules.countryDialCode"
              outlined
              prepend-icon="mdi-phone"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="
                    `https://flagcdn.com/16x12/${item.code.toLowerCase()}.png`
                  "
                  width="16"
                  height="12"
                  :alt="item.name"
                />
                <span class="px-1">{{ item.name }}</span>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              label="Phone Number"
              :rules="rules.phone"
              clearable
              outlined
              counter="9"
              v-model="phone"
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider />
        <br />

        <v-alert dense class="body-2" outlined type="orange" icon="mdi-alert">
          Note that you will be logged out to complete your new phone number
          confirmation process.
        </v-alert>

        <v-card-actions>
          <v-btn large depressed color="primary" @click="save">
            {{ $t("general.change") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";

export default {
  name: "ChangePhone",
  components: { FullView },
  data() {
    return {
      valid: false,
      phone: null,
      selectedCountry: null,
      countryCodes: [
        {
          name: "Jordan",
          nameAr: "الأردن",
          dialCode: "+962",
          code: "JO",
        },
        {
          name: "Yemen",
          nameAr: "اليمن",
          dialCode: "+967",
          code: "YE",
        },
      ],
      rules: {
        countryDialCode: [
          (v) =>
            !!v || "Pease select the country dia-code for the phone number",
        ],
        phone: [
          (v) => !!v || "Phone Number is required",
          (v) => {
            const re = /^\d{9}$/gi;
            return (
              (!!v && re.test(String(v))) ||
              "Wrong phone number pattern (e.g. 7123456789)"
            );
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
  },
  methods: {
    ...mapActions("auth", ["logout", "changePhone"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    save() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          const dialog = {
            title: "Change Phone Number",
            text: `Are you sure you want to change your phone number to (${this.selectedCountry.dialCode} ${this.phone})?`,
            actions: [
              {
                text: "Yes",
                click: () => {
                  const phone =
                    this.selectedCountry.dialCode + this.phone.trim();
                  this.changePhone(phone);
                  this.closeDialog();
                  this.$router.replace({ name: "login" });
                },
              },
              {
                text: "No",
                click: () => this.closeDialog(),
                color: "primary",
              },
            ],
          };

          this.showDialog(dialog);
        }
      }, 300);
    },
  },
};
</script>
