import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":"Change Phone Number"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c(VBtn,{attrs:{"large":"","depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])]},proxy:true}])},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"ma-5 pa-5",style:(_vm.getViewHeightStyle(76))},[_c('p',{staticClass:"px-5 pt-3 body-2"},[_vm._v(" Your current phone number is "),_c('strong',[_vm._v("("+_vm._s(_vm.getCurrentuser.phone)+")")]),_vm._v(", please enter your new number if you want to proceed: ")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VCombobox,{attrs:{"items":_vm.countryCodes,"label":"Country","item-text":"name","rule":_vm.rules.countryDialCode,"outlined":"","prepend-icon":"mdi-phone"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":("https://flagcdn.com/16x12/" + (item.code.toLowerCase()) + ".png"),"width":"16","height":"12","alt":item.name}}),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VTextField,{attrs:{"label":"Phone Number","rules":_vm.rules.phone,"clearable":"","outlined":"","counter":"9","validate-on-blur":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c(VDivider),_c('br'),_c(VAlert,{staticClass:"body-2",attrs:{"dense":"","outlined":"","type":"orange","icon":"mdi-alert"}},[_vm._v(" Note that you will be logged out to complete your new phone number confirmation process. ")]),_c(VCardActions,[_c(VBtn,{attrs:{"large":"","depressed":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.change"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }